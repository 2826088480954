<template>
    <div
        v-if="message"
        class="environment-alert px-4 py-1 text-center text-[12px] shadow-inner"
        :class="[
            isProduction
                ? 'bg-red-400 text-white'
                : 'bg-yellow-400 text-yellow-800',
        ]"
    >
        {{ message }}
    </div>
</template>

<script setup lang="ts">
const { user } = useAuth();

const message = computed(() => {
    return user.value && user.value.environmentAlert
        ? user.value.environmentAlert.message
        : null;
});

const isProduction = computed(() => {
    return user.value && user.value.environmentAlert
        ? user.value.environmentAlert.isProduction
        : false;
});
</script>
