<template>
    <div
        v-if="isImpersonatingUserName"
        class="impersonation-bar flex items-center justify-center gap-6 bg-red-400 px-6 py-2"
    >
        <span
            class="text-lg font-semibold text-white"
            data-cy="impersonating-name"
        >
            Impersonating {{ isImpersonatingUserName }}
        </span>

        <a
            class="btn-secondary-inverse block no-underline"
            :href="stopImpersonateUrl"
            data-cy="stop-impersonate-btn"
        >
            <div class="flex items-center justify-center gap-2">
                <Icon name="arrow-right-from-bracket-solid" class="flex"></Icon>
                <span class="leading-6">Stop</span>
            </div>
        </a>
    </div>
</template>

<script setup lang="ts">
const { user } = useAuth();

const isImpersonatingUserName = computed(() => {
    return user.value && user.value.impersonateUserName
        ? user.value.impersonateUserName
        : null;
});

const stopImpersonateUrl = computed(() => {
    return user.value && user.value.stopImpersonateUrl
        ? user.value.stopImpersonateUrl
        : null;
});
</script>
